import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Popup, Icon, Link, List, ListItem, Badge } from 'konsta/react';
import { Eye, EyeSlash, SliderHorizontal3 } from 'framework7-icons/react';
import { GroupContext } from '../context/group.context';
import SelectionList from '../components/selectionList';
import Loading from '../components/loading';
import { RouteContext } from '../context/route.context';

const WhosUpCardV2 = ({ item, index, showBalance }) => {
  const bg = `bg-${item.balance > 0 ? 'green' : 'red'}-${Math.floor((index % 4)) + 5}00`;
  return (
    <ListItem
      title={item.name}
      after={
        <Badge
          colors={{ bg }}
          className='text-lg'
        >
          {showBalance && item.balance}
        </Badge>
      }
      className={`${index === 0 ? 'border-2 border-rose-400 pt-2' : ''}`}
      style={{ opacity: `${1 - (index * 0.02)}`}}
    />
  );
};

export default function WhosUp() {
  const { balances: whosUp, getBalances, loading, group, shouldRefresh } = useContext(GroupContext);
  const { setRightContent } = useContext(RouteContext);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => setShowModal(!showModal), [showModal]);
  const [whosGoing, setWhosGoing] = useState([]);
  const [showBalance, setShowBalance] = useState(false);
  const toggleBalance = useCallback(() => setShowBalance(!showBalance), [showBalance]);

  const rightContent = useMemo(() => <>
      <Link 
        navbar 
        iconOnly
        className='mr-2'
        onClick={toggleBalance}>
          <Icon
            ios={showBalance ? <Eye className="w-7 h-7" /> : <EyeSlash className="w-7 h-7" />}
          />
      </Link>
      <Link 
        navbar 
        iconOnly 
        onClick={toggleModal}>
          <Icon
            ios={<SliderHorizontal3 className="w-7 h-7" />}
            badge={whosGoing.length > 0 ? whosGoing.length : null}
            badgeColors={{ bg: 'bg-gray-500' }}
          />
      </Link>
    </>, [showBalance, toggleBalance, toggleModal, whosGoing]);

  useEffect(() => { 
    setRightContent(rightContent);
  }, [rightContent, setRightContent]);
  
  useEffect(() => {
    if (shouldRefresh(whosUp)) {
      getBalances();
    }
  }, [group, whosUp, shouldRefresh, getBalances]);

  if (loading) return (<><Loading title="Who's Up?" /></>);

  return (<>
      <Popup opened={showModal} 
        onAbort={toggleModal}
        onBackdropClick={toggleModal}>
        <SelectionList
          title="Who's going?"
          subtitle="Select who's going to the event"
          selected={whosGoing}
          setSelected={setWhosGoing}
          list={whosUp}
          onClose={toggleModal}
          onSave={() => {
            toggleModal();
            getBalances();
          }}
        />
      </Popup>
      <List strongIos outlineIos insetIos className="mt-2 mb-2 flex-grow">
        {whosUp.filter((x) => whosGoing.length > 0 ? whosGoing.includes(x.id) : true).map((item, index) => (
          <WhosUpCardV2 key={item.id} item={item} index={index} showBalance={showBalance} />
        ))}
      </List>
      </>);
};
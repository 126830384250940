import './App.css';
import { App as KonstaApp } from 'konsta/react';
import Layout from './Layout';
import AuthContextProvider from './context/auth.context';
import GroupContextProvider from './context/group.context';
import { RouteContextProvider } from './context/route.context';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <KonstaApp theme="ios">
      <Router>
        <AuthContextProvider>
          <RouteContextProvider>
            <GroupContextProvider>
              <Layout />
            </GroupContextProvider>
          </RouteContextProvider>
        </AuthContextProvider>
      </Router>
    </KonstaApp>
  );
}

export default App;

import React, { useContext, useState } from 'react';
import { Briefcase, CarFill, Person2Alt } from 'framework7-icons/react';
import { Popup, Block } from 'konsta/react';
import { useLocation } from 'react-router-dom';
import WhosUp from '../pages/WhosUp';
import Login from '../pages/Login';
import Outings from '../pages/Outings';
import { AuthContext } from './auth.context';

export const RouteContext = React.createContext();

const size = "w-8 h-8";

const allRoutes = [
  {
    name: 'login',
    component: Login,
    label: 'Login',
    icon: <Person2Alt className={`${size}`} />,
    authRoute: true
  },
  {
    name: 'whosup',
    component: WhosUp,
    label: "Who's up?",
    icon: <Briefcase className={`${size}`} />,
    default: true,
    authRequired: true
  },
  {
    name: 'outings',
    component: Outings,
    label: 'Outings',
    icon: <CarFill className={`${size}`} />,
    authRequired: true
  }
];

export function RouteContextProvider({ children }) {
  const location = useLocation();
  const [leftContent, setLeftContent] = useState(null);
  const [rightContent, setRightContent] = useState(null);
  const [globalPopup, setGlobalPopup] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);


  const GlobalPopup = () => {
    return <Popup opened={!!globalPopup} 
      onBackdropClick={() => setGlobalPopup(null)}
      onAbort={() => setGlobalPopup(null)}>
        {globalPopup?.content}
        {!globalPopup?.content && <Block />}
    </Popup>;
  }

  const values =  {
    defaultRoute: allRoutes.find(x => x.default)?.name,
    authRoute: allRoutes.find(x => x.authRoute)?.name,
    visibleRoutes: allRoutes.filter(x => !x.hidden && (isLoggedIn ? !x.authRoute : !x.authRequired)),
    pageTitle: allRoutes.find(x => x.name === location?.pathname?.replace('/', ''))?.label,
    location,
    rightContent,
    leftContent,
    setRightContent,
    setLeftContent,
    GlobalPopup,
    setGlobalPopup,
    globalPopupName: globalPopup?.name
  };

  

  return <RouteContext.Provider value={values}>
    {children}
    </RouteContext.Provider>;
}
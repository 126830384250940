import React from 'react';
import { Button, Page, Navbar, Icon, Link, List, ListItem, Checkbox, BlockTitle, Radio } from 'konsta/react';
import { ChevronDown } from 'framework7-icons/react';
export default function SelectionList({
  title,
  subtitle,
  list,
  selected: selectedLocal,
  setSelected: setSelectedLocal,
  onClose,
  onSave,
  single = false,
  sortAlphabetically = true,
  sortKey = 'name'
}) {
  const [currentlySelected, setCurrentlySelected] = React.useState(selectedLocal);
  let setSelected = onSave ? setCurrentlySelected : setSelectedLocal;
  let selected = onSave ? currentlySelected : selectedLocal;

  if (!onSave) {
    onSave = onClose;
  }
  const selectAll = () => {
    if (list.length === selected.length) {
      setSelected([]);
    } else {
      setSelected(list?.map(x => x.id));
    }
  };

  const selectMany = (e) => {
    const { target: { value: id }} = e;
    if (selected.find(x => x === id)) {
      setSelected(selected.filter(x => x !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const selectOne = (e) => {
    const { target: { value: id }} = e;
    setSelected(list.find(x => x.id === id));
  };

  const save = () => {
    setSelectedLocal(currentlySelected);
    onSave();
  }

  const renderedList = sortAlphabetically && list.some(x => x[sortKey]) ? list?.slice(0).sort((x, y) => x[sortKey].localeCompare(y[sortKey])) : list;

     
  return (<Page>
    <Navbar title={title}
      right={<Link navbar iconOnly onClick={onClose} >
        <Icon ios={<ChevronDown className="w-7 h-7" />} />
        </Link>} 
    />
      <BlockTitle>
        {subtitle}
        {!single && <Checkbox 
          checked={list.length === selected.length} 
          indeterminate={selected.length > 0 && list.length !== selected.length} 
          component="div"
          onChange={selectAll}
          onClick={selectAll}
        />}
      </BlockTitle>
    <List>
      {renderedList?.map((item) => (<ListItem 
        key={item.id}
        title={item.name}
        style={{cursor: "pointer" }}
        onClick={() => single ? selectOne({ target: { value: item.id }}) : selectMany({ target: { value: item.id }})}
        media={
          !single
          ? 
            <Checkbox 
              value={item.id} 
              checked={single ? selected?.id === item.id : selected?.includes(item.id)} 
              component="div" 
              onChange={selectMany} 
            /> 
          : 
            <Radio 
              value={item.id} 
              checked={single ? selected?.id === item.id : selected?.includes(item.id)} 
              component="div" 
              onChange={selectOne}
            />
        }  />
      ))}
    </List>
    {onSave && <Button
      raised large
      onClick={save}
    >
      Save
    </Button>}
  </Page>);
}
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
export const AuthContext = createContext();


export default function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const isLoggedIn = user !== null && loading === false;

  useEffect(() => {
    const urlToken = new URLSearchParams(window.location.search).get('token');
    if (urlToken) {
      localStorage.setItem('token', urlToken);
    }
    const localToken = urlToken || localStorage.getItem('token');
    if (localToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localToken}`;
      setToken(localToken);
      axios.get('/api/users/profile')
        .then(res => setUser(res.data))
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, token, loading, isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
}
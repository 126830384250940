import { ChevronDownCircle } from 'framework7-icons/react';
import { Link, Icon } from 'konsta/react';
import { useCallback, useContext, useEffect } from 'react';
import SelectionList from './selectionList';
import { RouteContext } from '../context/route.context';
import { GroupContext } from '../context/group.context';

export default function GroupDropdown() {
  const { setGlobalPopup, globalPopupName } = useContext(RouteContext);
  const { group, setGroup, groups } = useContext(GroupContext);

  const globalPopupContent = useCallback(() => (
    <SelectionList
      title="Select group"
      subtitle="Select which group to view"
      single={true}
      selected={group}
      setSelected={setGroup}
      list={groups}
      onClose={() => setGlobalPopup(null)}
      onSave={() => setGlobalPopup(null)}
    />), [group, groups]);

  const openModal = () => {
    setGlobalPopup({ name: 'groupDropdown', content: globalPopupContent() });
  };

  useEffect(() => {
    if (globalPopupName === 'groupDropdown') {
      openModal();
    }
  }, [group, groups]);

  return (<>
    <Link 
        navbar 
        iconOnly
        className='mr-2'
        onClick={() => openModal()}>
          <span className="px-2">{group?.name}</span>
          <Icon
            ios={<ChevronDownCircle className="w-7 h-7" />}
          />
      </Link>
  </>);
}
import React from 'react';
import { Page, Block, Button } from 'konsta/react';

export default function Login() {
  return (
    <Page>
      <Block strong>
        Please log in to continue.
      </Block>
      <Button 
      large
      href="/api/auth/login/splitwise">Login with Splitwise</Button>
    </Page>
  );
}
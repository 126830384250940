import React from 'react';
import { Page, Block, Preloader } from 'konsta/react';

export default function Loading({ title }) {
  return (
    <Page >
      <Block className="flex items-center justify-center h-screen">
        <Preloader className="w-24 h-24" />
      </Block>
    </Page>
  );
}
import React, { useState, useEffect, useContext } from 'react';
import { Popup,List, BlockTitle, ListInput, Button} from 'konsta/react';
import { Pie, PieChart, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import { AuthContext } from '../context/auth.context';
import { RouteContext } from '../context/route.context';
import Outing from '../components/Outing';
import Loading from '../components/loading';
import GroupDropdown from '../components/groupDropdown';
import { GroupContext } from '../context/group.context';




export default function Outings() {
  const { setLeftContent } = useContext(RouteContext);
  const { group, shouldRefresh, getOutings, loading, outings, setOutings } = useContext(GroupContext);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const toggleDetails = (id) => {
    setOutings(outings.map(outing => outing.id === id ? { ...outing, expanded: !outing.expanded, showParticipants: false } : { ...outing, expanded: false, showParticipants: false }));
  };

  const toggleParticipants = (id) => {
    setOutings(outings.map(outing => outing.id === id ? { ...outing, showParticipants: !outing.showParticipants } : { ...outing, showParticipants: false }));
  };

 
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  useEffect(() => {
    setLeftContent(<GroupDropdown />)
  }, [setLeftContent]);

  useEffect(() => {
    if (shouldRefresh(outings)) {
      getOutings();
    }
  }, [group, outings, shouldRefresh, getOutings]);

  const groupOutings = () => {
    const groupedOutings = [];
    outings.forEach(outing => {
      const existing = groupedOutings.findIndex(group => group.name === outing.location);
      if (existing !== -1) {
        groupedOutings[existing].outings.push(outing);
        groupedOutings[existing].amount += Number(outing.amount);
      } else {
        groupedOutings.push({ name: outing.location, outings: [outing], amount: Number(outing.amount) });
      }
    });
    return groupedOutings;  
  };

  const outingsGrouped = groupOutings();
  const smallChart = outings.some(x => x.showParticipants);
  if (loading) return (<><Loading title="Outings" /></>);

  return (<>
    <Popup
      opened={showModal}
      onAbort={toggleModal}
      onBackdropClick={toggleModal}
      className="p-4"
    >
      <BlockTitle>Add an outing</BlockTitle>
      <List>
        <ListInput
          label="Name"
          type="text"
          placeholder="Name"
        />
        <ListInput
          label="Date"
          type="date"
          placeholder="Date"
        />
        <ListInput
          label="Location"
          type="text"
          placeholder="Location"
        />
      </List>
      <Button disabled>Save</Button>
      <Button onClick={toggleModal}>Cancel</Button>
    </Popup>
    <ResponsiveContainer width="100%" height={smallChart ? 65 : 120} className="flex-shring-0">
      <PieChart>
          <Tooltip />
          <Pie 
            startAngle={0}
            endAngle={180}
            data={outingsGrouped} 
            dataKey="amount" 
            nameKey="name" 
            cx="50%" 
            cy="100%" 
            outerRadius={smallChart ? 50: 100} 
            >
              {outingsGrouped.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    <List strong className="mt-0 mb-0 flex-grow overflow-y-auto">
      {outings.map(outing => <Outing key={outing.id} data={outing} toggleDetails={toggleDetails} toggleParticipants={toggleParticipants} />)}
    </List>
  </>);
}
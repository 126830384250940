import React, { createContext, useEffect, useState, useContext, useCallback, useRef } from 'react';
import axios from 'axios';
import { RouteContext } from './route.context';
import { AuthContext } from './auth.context';
import GroupDropdown from '../components/groupDropdown';

export const GroupContext = createContext();

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const similarLocations = [
  {
    name: 'SGD',
    terms: ['sgd', 'so gong dong', 'sun du bu', 'korean', 'tofu stew']
  },
  {
    name: 'Saigon Pho',
    terms: ['pho', 'vietnamese', 'noodle', 'soup']
  },
  {
    name: 'Don Jose',
    terms: ['mexican', 'taco', 'burrito', 'enchilada']
  },
  {
    name: 'Nonnas',
    terms: ['italian', 'pasta', 'pizza']
  },
];

export default function GroupContextProvider({ children }) {
  const { setLeftContent } = useContext(RouteContext);
  const { isLoggedIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [balances, setBalances] = useState([]);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState({});
  const [outings, setOutings] = useState([]);
  const previousGroup = usePrevious(group);
  const [groupChanged, setGroupChanged] = useState(false);
  const groupEmpty = !group?.id && group?.id !== 0;
  const previousGroupEmpty = !previousGroup?.id && previousGroup?.id !== 0;

  useEffect(() => {
    if (!groupEmpty && !previousGroupEmpty && group?.id !== previousGroup?.id) {
      setGroupChanged(true);
    } else {
      setGroupChanged(false);
    }
  }, [group, previousGroup]);

  const shouldRefresh = (list) => {
    const listEmpty = !list || list?.length === 0;
    return !groupEmpty && 
      (listEmpty || groupChanged);

  };

  const getOutings = async () => {
    setLoading(true);
    const { data } = await axios.get('/api/outings/' + group.id);
    if (data) {
      const uniqueLocations = data.map(outing => {
        const location = similarLocations.find(loc => [loc.name.toLowerCase(), ...loc.terms].some(term => outing.description.toLowerCase().includes(term)));
        outing.location = location ? location.name : 'Other';
        return outing;
      });
      setOutings(uniqueLocations);
      setLoading(false);
    }
  };


  const getBalances = async () => {
    setLoading(true);
    const { data } = await axios.get('/api/balances/' + group.id);
    setBalances(data);
    setLoading(false);
  };

  const getGroups = useCallback(async () => {
    setLoading(true);
    const { data } = await axios.get('/api/groups');
    setLoading(false);
    setGroups(data);
    if (!group?.id && data.length > 0) {
      setGroup(data[0]);
    }
    return data;
  }, [group]);
  
  
  useEffect(() => {
    if (isLoggedIn && !groups?.length > 0) {
      getGroups().then(x => setLeftContent(<GroupDropdown />));
    }
  }, [isLoggedIn, groups, getGroups, setLeftContent]);


  const values = {
    loading,
    balances,
    getBalances,
    groups,
    getGroups,
    group,
    setGroup,
    shouldRefresh,
    getOutings,
    outings, 
    setOutings
  };

  return (
    <GroupContext.Provider value={values}>
      {children}
    </GroupContext.Provider>
  );
}
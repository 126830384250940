import React, { useContext, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { Page, Tabbar, TabbarLink, Icon, Navbar } from 'konsta/react';
import { AuthContext } from './context/auth.context';
import { RouteContext } from './context/route.context';
import Loading from './components/loading';

export default function Layout() {
  const { loading, isLoggedIn } = useContext(AuthContext);
  const { visibleRoutes, defaultRoute, authRoute, location, pageTitle, leftContent, rightContent, GlobalPopup } = useContext(RouteContext);
  const navigate = useNavigate();


  if (loading) 
    return (<Loading />);

  return (<>
    <Page>
      <Navbar left={leftContent} title={pageTitle} right={rightContent} />
      <GlobalPopup />
      <div className="flex flex-col " style={{ height: 'calc(100vh - 4rem)'}}>
        <Routes>
          {visibleRoutes
            .map(({ name, component }) => 
              <Route key={name} path={name} Component={component} />
            )}
          <Route 
            path="*" 
            element={!isLoggedIn ? 
              <Navigate to={authRoute} /> : 
              <Navigate to={defaultRoute} />}
          />
          
        </Routes>
      </div>
    </Page>
    <Tabbar 
      icons={true} 
      labels={true} 
      className={`left-0 bottom-0 fixed w-full h-16 pt-2`}>
      {visibleRoutes.map(({ icon, label, name }) => (
        <TabbarLink 
          key={name}
          icon={<Icon ios={icon} />} 
          label={label}
          active={location?.pathname === `/${name}`}
          onClick={() => navigate(name)}
        />
      ))}
    </Tabbar>
  </>);
}

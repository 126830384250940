import React, { useEffect, useState } from 'react';
import { ListItem, Icon, List } from 'konsta/react';
import { ChevronUp } from 'framework7-icons/react';

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default function Outing({ data: outing, toggleDetails, toggleParticipants }) {
  const [expanded, setExpanded] = useState(outing.expanded || false);
  useEffect(() => setExpanded(outing.expanded), [outing.expanded]);
  return (<ListItem 
    link={!expanded} 
    after={<><span>{new Date(outing.date).toLocaleDateString()}</span>{outing.expanded ? <Icon ios={<ChevronUp />} /> : null}</>}
    onClick={() => toggleDetails(outing.id)}
    key={outing.id} 
    title={`${outing.location}`}>
    {expanded &&
      <List inset className="mt-0 mb-0 bg-gray-800">
        <ListItem title={<b>Payer</b>}
          after={outing.payer.first_name} />
        <ListItem title={<b>Amount</b>}
          after={USDollar.format(outing.amount)} />
        <ListItem title={<b>Description</b>}
          after={outing.description} />
        <ListItem 
          link title={<b>Participants</b>} 
          after={<><span>{outing.attendees.length}</span>{outing.showParticipants ? <Icon ios={<ChevronUp />} />: null }</>}
          onClick={(e) => { 
            e.stopPropagation(); 
            toggleParticipants(outing.id);
          }}>
          {outing.showParticipants && <List inset>
            {outing.attendees.map(participant => (
              <ListItem key={participant.splitwise_id} title={participant.first_name} />
            ))}
          </List>}
        </ListItem> 

      </List>}
    </ListItem>
  );
}